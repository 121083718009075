import { FC, ReactNode } from "react";
import PropTypes from "prop-types";
import { Outlet } from "react-router-dom";
import Navbar from "src/components/home/Navbar";
import Footer from "src/components/home/Footer";

import { Container, Box } from "@mui/material";

interface ReportLayoutProps {
	children?: ReactNode;
}

const ReportLayout: FC<ReportLayoutProps> = ({ children }) => {
	return (
		<Box
			sx={{
				background: `url(${process.env.REACT_APP_IMAGE_URL}static/images/placeholders/covers/report-bg.jpg)`,
				backgroundSize: "cover",
				backgroundRepeat: "no-repeat",
				backgroundPosition: "center",
				backgroundAttachment: "fixed",
			}}>
			<Navbar />
			<Container
				maxWidth="lg"
				sx={{ position: "relative", marginTop: "90px", minHeight: "100vh" }}>
				{children || <Outlet />}
			</Container>
			<Footer />
		</Box>
	);
};

ReportLayout.propTypes = {
	children: PropTypes.node,
};

export default ReportLayout;
