import { axiosInstance } from "./axiosInstance";
import { logAnalyticsEvent } from "src/utils/Firebase";
import Swal from "sweetalert2";

export const AuthService = {
	login: async (email: string, password: string) => {
		return await axiosInstance.post("/api/auth/user/login", {
			email: email,
			password: password,
		});
	},
	register: async (
		name: string,
		email: string,
		username: string,
		phone: string,
		password: string,
		source: string,
		referral: string,
		recaptcha: string
	) => {
		return await axiosInstance.post("/api/auth/user/register", {
			name: name,
			email: email,
			username: username,
			phone: phone,
			password: password,
			source: source,
			referral_code: referral,
			recaptcha: recaptcha,
		});
	},
	me: async () => {
		const response = await axiosInstance.get("/api/auth/user/me");
		localStorage.setItem("USER", JSON.stringify(response.data.user));
		return response;
	},
	resetPassword: async (oldPassword: string, newPassword: string) => {
		const response = await axiosInstance.post(
			"/api/feature/user/reset-password",
			{
				old_password: oldPassword,
				new_password: newPassword,
			}
		);
		return response;
	},
};

export const loginGoogle = async () => {
	window.location.href = `${process.env.REACT_APP_API_URL}/api/auth/user/social/login/google/`;
}
	

export const logout = () => {
	Swal.fire({
		title: "Apakah Anda yakin?",
		text: "Akun Anda akan keluar dari aplikasi",
		icon: "warning",
		showCancelButton: true,
		confirmButtonText: "Ya, keluar!",
		cancelButtonText: "Tidak",
		confirmButtonColor: "#3C6F7D",
	}).then((result) => {
		if (result.isConfirmed) {
			logAnalyticsEvent("logout", { method: "delete_token" });
			const SubmateriProgress = JSON.parse(
				localStorage.getItem("SubmateriProgress") || "[]"
			);
			const seeTourDashboardCounter = localStorage.getItem("seeTourDashboardCounter");
			localStorage.clear();
			localStorage.setItem(
				"SubmateriProgress",
				JSON.stringify(SubmateriProgress)
			);
			seeTourDashboardCounter && localStorage.setItem("seeTourDashboardCounter", seeTourDashboardCounter);
			window.location.href = "/login";
		}
	});
};

export const getCurrentUser = () => {
	return JSON.parse(localStorage.getItem("USER") || "{}");
};

export const isLoggedIn = () => {
	return localStorage.getItem("TOKEN") ? true : false;
};

export const isParentLoggedIn = () => {
	return localStorage.getItem("PARENT_TOKEN") ? true : false;
}

export const isTentorLoggedIn = () => {
	return localStorage.getItem("TENTOR_TOKEN") ? true : false;
}

/* Forget Password */
export const forgetPassword = async (email: string) => {
	return await axiosInstance.get(
		"/api/auth/user/forgot-password/create?email=" + email
	);
};

export const resendForgetPassword = async (email: string) => {
	return await axiosInstance.get(
		"/api/auth/user/forgot-password/update?email=" + email
	);
};

export const validateForgetPassword = async (email: string, otp: string) => {
	return await axiosInstance.post("/api/auth/user/validate-forgot-password", {
		email: email,
		otp: otp,
	});
};

export const updateForgetPassword = async (
	email: string,
	otp: string,
	password: string
) => {
	return await axiosInstance.post("/api/auth/user/update-forgot-password", {
		email: email,
		otp: otp,
		password: password,
	});
};

/* Verify Phone */
export const requestOTP = async () => {
	return await axiosInstance.get("/api/auth/user/register/send-otp");
};

export const requestResendOTP = async (phone: string) => {
	return await axiosInstance.get("/api/auth/user/register/resend-otp");
};

export const verifyOTP = async (otp: string) => {
	return await axiosInstance.post("/api/auth/user/register/verify-otp", {
		otp: otp,
	});
};
