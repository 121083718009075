import Logo from "src/components/common/LogoSign";
import {
	Box,
	Container,
	Button,
	Typography,
	styled,
	useTheme,
	Input,
} from "@mui/material";
import UserBox from "src/components/common/UserBox";
import PersonIcon from "@mui/icons-material/Person";
import { isLoggedIn, isParentLoggedIn } from "src/services/auth.service";
import Link from "src/components/common/Link";
import { NavLink as RouterLink, useSearchParams } from "react-router-dom";
import { useLocation, useNavigate } from "react-router";
import getParent from "src/models/Parent";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSearch } from "src/features/home/program/programSlice";

const NavbarWrapper = styled(Box)(
	({ theme }) => `
		top: 0;
		left: 0;
		right: 0;
		z-index: 99;
		background-color: ${theme.colors.alpha.trueWhite[100]};
`
);

const ButtonList = styled(Button)(
	() => `
	margin: 0 20px;
	padding: 5px 0px;

`
);

const NavbarDesktop = ({position}) => {
	// const [params, setParams] = useSearchParams();
	const {search} = useSelector((state: any) => state.programSlice);
	const [searchValue, setSearchValue] = useState(search);
	const theme = useTheme();
	const location = useLocation();
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const NavButton = ({ to, label, location, onClick, endIcon = null }) => {
		const isActive = location.pathname === to;
		const fontWeight = isActive ? "900" : "500";
		const backgroundColor = isActive
			? theme.colors.primary.main
			: "transparent";
		const color = isActive
			? theme.colors.alpha.trueWhite[100]
			: theme.colors.alpha.black[100];

		return (
			<Button
				disableRipple
				component={RouterLink}
				to={to}
				variant="text"
				color="primary"
				// fullWidth
				sx={{ margin: "0 10px", padding: "5px 20px", color, backgroundColor, '&:hover': { backgroundColor } }}>
				{label}
			</Button>
		);
	};

	const Menus = () => {
		return (
			<>
				<NavButton to="/" label="Home" location={location} onClick="" />
				<NavButton
					to="/programs"
					label="Programs"
					location={location}
					onClick=""
				/>
				<NavButton
					to="/testimony"
					label="Testimonies"
					location={location}
					onClick=""
				/>
				<NavButton
					to="/blogs"
					label="News"
					location={location}
					onClick=""
				/>
				<NavButton
					to="/jobs"
					label="Jobs"
					location={location}
					onClick=""
				/>
				{/* <a href="#footer">
					<ButtonList
						variant="text"
						color="primary"
						style={{
							fontWeight: "500",
							color: theme.colors.alpha.black[100],
							width: "100%",
						}}>
						Contact
					</ButtonList>
				</a> */}
				
				{isParentLoggedIn() && getParent() && (
					<NavButton
					to={`/report/member/${getParent().token}`}
					label="Report"
					location={location}
					onClick=""
					/>
				)}
			</>
		)
	}

	useEffect(() => {
		dispatch(setSearch({search: ""}))
	}, [])

	return (
		<>
			<NavbarWrapper
				sx={{
					background: "transparent",
					opacity: 0.85,
					mt: "16px",
					position: position
				}}>
				<Container maxWidth="lg">
					<Box
						sx={{
							px: 4,
							borderRadius: "40px",
							border: "1px solid #eee",
							boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
							backgroundColor: `${theme.colors.alpha.trueWhite[100]}`,
							opacity: 0.9,
							height: "64px",
						}}
						display="flex"
						justifyContent="space-between"
						py={0}
						alignItems="center">
						<Logo />
						<Box display="flex" alignItems="center" sx={{display: (location.pathname == "/programs" && search != "" ) ? "flex" : "none"}}>
							<Menus />
						</Box>
						<div style={{ display: "flex", alignItems: "center", justifyContent: "end", height: "100%" }}>
							<Box display="flex" alignItems="center" sx={{ mr: 6, display: (location.pathname != "/programs" || search == "") ? "flex" : "none"}}>
								<Menus />
							</Box>
							{(location.pathname != "/programs" || search == "") &&
								<Input
									placeholder="Search Program"
									sx={{ mt: 0, width: "17ch" }}
									startAdornment={
										<img
											src="/static/images/placeholders/icons/search.svg"
											alt="icon search"
											style={{ marginRight: "10px" }}
										/>
									}
									value={searchValue}
									onChange={(e) => {
										setSearchValue(e.target.value)
									}}
									onKeyDown={(e) => {
										if (e.key === "Enter") {
											e.preventDefault();
											navigate(`/programs`);
											console.log(searchValue)
											dispatch(setSearch({search: searchValue}))
											// dispatch(setSearch({search: ""}))
										}
									}}
								/>
							}
						{isLoggedIn() ? (
							<Box sx={{ my: 0.8 }}>
								<UserBox nameUser={false} />
							</Box>
						) : (
							<Box sx={{ my: 2 }}>
								<Link to="/login">
									<Box sx={{ mr: 2, display: "flex", alignItems: "center" }}>
										<Typography variant="h6" sx={{ fontWeight: "500" }}>
											Sign In
										</Typography>
										<Box
											sx={{
												backgroundColor: theme.colors.primary.main,
												margin: "0 10px",
												p: 0.25,
												borderRadius: "50%",
												height: "30px",
												width: "30px",
											}}>
											<PersonIcon sx={{ color: "#fff" }} />
										</Box>
									</Box>
								</Link>
							</Box>
						)}
						</div>
					</Box>
				</Container>
			</NavbarWrapper>
		</>
	);
};

export default NavbarDesktop;
