import { FC, ReactNode } from "react";
import PropTypes from "prop-types";
import { Outlet } from "react-router-dom";
import Navbar from "src/components/home/Navbar";
import Footer from "src/components/home/Footer";

import { Container, Box } from "@mui/material";

interface HomeLayoutProps {
	children?: ReactNode;
}

const HomeLayout: FC<HomeLayoutProps> = ({ children }) => {
	return (
		<Box sx={{ backgroundColor: "white" }}>
			<Navbar />
			<Container maxWidth="lg" sx={{ position: "relative", marginTop: "90px", minHeight: "100vh" }}>
				{children || <Outlet />}
			</Container>
			<Footer />
		</Box>
	);
};

HomeLayout.propTypes = {
	children: PropTypes.node,
};

export default HomeLayout;
