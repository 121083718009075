import { FC, ReactNode } from "react";
import PropTypes from "prop-types";
import { Outlet } from "react-router-dom";
import Navbar from "src/components/home/Navbar";
import Footer from "src/components/home/Footer";

import { Container, Box } from "@mui/material";

interface JobsLayoutProps {
  children?: ReactNode;
}

const JobsLayout: FC<JobsLayoutProps> = ({ children }) => {
  return (
    <Box sx={{ backgroundColor: "white" }}>
      <Navbar position="static" />
      <Container maxWidth="lg" sx={{ position: "relative", minHeight: "100vh" }}>
        {children || <Outlet />}
      </Container>
      <Footer />
    </Box>
  );
};

JobsLayout.propTypes = {
  children: PropTypes.node,
};

export default JobsLayout;
