import { Box } from "@mui/material";

import NavbarDesktop from "src/components/home/Navbar/NavbarDesktop";
import NavbarMobile from "src/components/home/Navbar/NavbarMobile";

const Navbar = ({ position = "fixed" }) => {
	return (
		<>
			<Box sx={{ display: { xs: "none", lg: "block" } }}>
				<NavbarDesktop position={position} />
			</Box>
			<Box sx={{ display: { xs: "block", lg: "none" } }}>
				<NavbarMobile position={position} />
			</Box>
		</>
	);
};

export default Navbar;
