import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ProgramState {
    search: string
}

const initialState: ProgramState = {
    search: "",
};

export const programSlice = createSlice({
    name: 'program',
    initialState,
    reducers: {
        setProgram: (state, action: PayloadAction<ProgramState>) => {
            return {...action.payload};
        },
        setSearch: (state, action: PayloadAction<ProgramState>) => {
            state.search = action.payload.search
        },
    }
})

export const { setProgram, setSearch } = programSlice.actions;

export default programSlice.reducer