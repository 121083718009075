import {
	Box,
	Tooltip,
	Badge,
	TooltipProps,
	tooltipClasses,
	styled,
	useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";

const LogoWrapper = styled(Link)(
	({ theme }) => `
        color: ${theme.palette.text.primary};
        display: flex;
        text-decoration: none;
        // width: 53px;
        // margin: 0 auto;
        font-weight: ${theme.typography.fontWeightBold};
`
);

const LogoSignWrapper = styled(Box)(
	() => `
        height: 38px;
`
);

const TooltipWrapper = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: theme.palette.background.paper,
		color: theme.palette.getContrastText(theme.palette.background.paper),
		fontSize: theme.typography.pxToRem(12),
		fontWeight: "bold",
		borderRadius: theme.general?.borderRadiusSm,
		boxShadow:
			"0 .2rem .8rem rgba(7,9,25,.18), 0 .08rem .15rem rgba(7,9,25,.15)",
	},
	[`& .${tooltipClasses.arrow}`]: {
		color: theme.palette.background.paper,
	},
}));

function Logo({ isWhite = false, scale = 1 }) {
	const theme = useTheme();
	return (
		<TooltipWrapper title="Brofesional" arrow>
			<LogoWrapper to="/overview">
				<LogoSignWrapper>
					<img
						src={
							isWhite
								? `${process.env.REACT_APP_IMAGE_URL}static/images/logo/brofesional-white.png`
								: `${process.env.REACT_APP_IMAGE_URL}static/images/logo/brofesional_2.png`
						}
						width="160px"
						alt="Brofesional logo"
						style={{ transform: `scale(${scale})` }}
					/>
				</LogoSignWrapper>
			</LogoWrapper>
		</TooltipWrapper>
	);
}

export default Logo;
