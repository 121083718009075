import { useContext, useState, useEffect } from "react";

import {
	ListSubheader,
	alpha,
	Box,
	List,
	styled,
	Button,
	ListItem,
	Collapse,
} from "@mui/material";
import { NavLink as RouterLink } from "react-router-dom";
import { SidebarContext } from "src/contexts/SidebarContext";
import { useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import getUser from "src/models/User";
import { getQuizParam, getLinkBackToCourse } from "src/utils/Quiz";
import { getTryoutParam } from "src/utils/Tryout";

// analytic
import ShoppingCartSharpIcon from "@mui/icons-material/ShoppingCartSharp";
import CalendarMonthSharpIcon from "@mui/icons-material/CalendarMonthSharp";
import ArticleIcon from "@mui/icons-material/Article";
import ConfirmationNumberTwoToneIcon from '@mui/icons-material/ConfirmationNumberTwoTone';
import DesignServicesTwoToneIcon from "@mui/icons-material/DesignServicesTwoTone";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import AccountCircleTwoToneIcon from "@mui/icons-material/AccountCircleTwoTone";
import BallotTwoToneIcon from "@mui/icons-material/BallotTwoTone";
import ChromeReaderModeTwoToneIcon from "@mui/icons-material/ChromeReaderModeTwoTone";
import YouTubeIcon from "@mui/icons-material/YouTube";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import PlayCircleFilledWhiteOutlinedIcon from "@mui/icons-material/PlayCircleFilledWhiteOutlined";
import InsertPhotoOutlinedIcon from "@mui/icons-material/InsertPhotoOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import OndemandVideoOutlinedIcon from "@mui/icons-material/OndemandVideoOutlined";
import InsertLinkOutlinedIcon from "@mui/icons-material/InsertLinkOutlined";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import AudioFileOutlinedIcon from "@mui/icons-material/AudioFileOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import QuizOutlinedIcon from "@mui/icons-material/QuizOutlined";
import LockOpenTwoToneIcon from "@mui/icons-material/LockOpenTwoTone";
import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import SchoolTwoToneIcon from '@mui/icons-material/SchoolTwoTone';
import { logout } from "src/services/auth.service";

const MenuWrapper = styled(Box)(
	({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(1)};

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.colors.alpha.black[50]};
      padding: ${theme.spacing(0, 2.5)};
      line-height: 1.4;
    }
`
);

const SubMenuWrapper = styled(Box)(
	({ theme }) => `
    .MuiList-root {

      .MuiListItem-root {
        padding: 1px 0;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
    
        .MuiButton-root {
          display: flex;
          color: ${theme.colors.secondary.dark};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 3)};

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(["color"])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.colors.secondary.light};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }
          
          .MuiButton-endIcon {
            color: ${theme.colors.secondary.light};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.active,
          &:hover {
            background-color: ${alpha(theme.colors.primary.main, 0.06)};
            color: ${theme.colors.alpha.black[100]};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.black[100]};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${theme.colors.alpha.trueWhite[100]};
                opacity: 0;
                transition: ${theme.transitions.create([
									"transform",
									"opacity",
								])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.active,
              &:hover {

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`
);

function SidebarMenu() {
	const { closeSidebar } = useContext(SidebarContext);
	const [openDropdown, setOpenDropdown] = useState({});
	const location = useLocation();
	const { id, courseId, membershipId, classId } = useParams();
	const courseState = useSelector((state: any) => state.course);
	const materi = courseState.materials;
	const quizParam = getQuizParam();
	const tryoutParam = getTryoutParam();
	const detailTryout = location.pathname.includes("/classes/tryout");
	const detailCourse = location.pathname.includes("/classes/e-course");
	const detailClassRoom = location.pathname.includes("/classes/classroom");

	useEffect(() => {
		materi && materi.length == 1 && setOpenDropdown({ 0: true }); // open dropdown if only have 1 materi
	}, [materi]);

	function truncateText(text, maxLength) {
		return text.length <= maxLength
			? text
			: `${text.substring(0, maxLength)}...`;
	}

	const getIconMaterial = (type: string, isDone: boolean) => {
		if (isDone) return <CheckOutlinedIcon style={{ color: "green" }} />;

		return type === "video" ? (
			<OndemandVideoOutlinedIcon style={{ color: isDone ? "red" : "" }} />
		) : type === "youtube" ? (
			<YouTubeIcon style={{ color: isDone ? "red" : "" }} />
		) : type === "audio" ? (
			<AudioFileOutlinedIcon style={{ color: isDone ? "red" : "" }} />
		) : type === "link" ? (
			<InsertLinkOutlinedIcon style={{ color: isDone ? "red" : "" }} />
		) : type === "ebook" ? (
			<MenuBookOutlinedIcon style={{ color: isDone ? "red" : "" }} />
		) : type === "image" ? (
			<InsertPhotoOutlinedIcon style={{ color: isDone ? "red" : "" }} />
		) : type === "quiz" ? (
			<DescriptionOutlinedIcon style={{ color: isDone ? "red" : "" }} />
		) : (
			<ChromeReaderModeTwoToneIcon style={{ color: isDone ? "red" : "" }} />
		);
	};

	const getUrl = (materiId, submaterialId) => {
		if (detailClassRoom) {
			return `/classes/classroom/${classId}/${courseId}/${materiId}/${submaterialId}`;
		}
		return `/classes/e-course/${membershipId}/${courseId}/${materiId}/${submaterialId}`;
	};

	let materialItems = [];
	materi &&
		materi.map((materi, index) => {
			materialItems.push({
				title: openDropdown[index]
					? materi.name
					: truncateText(materi.name, 20),
				isCollapse: true,
				icon: <ArticleIcon style={{ color: "black" }} />,
				onclick: () =>
					setOpenDropdown({ ...openDropdown, [index]: !openDropdown[index] }),
				open: openDropdown[index],
				subitems: materi.submaterials.map((item) => ({
					id: item.id,
					title: item.name,
					icon: getIconMaterial(item.type, item.is_done),
					to: getUrl(materi.id, item.id),
					tryout: item.tryout,
					is_done: item.is_done,
				})),
			});
		});

	const countTotalPretest = (data) => {
		return data.tryout.filter((item) => item.type === "pretest").length;
	};

	const getIsSubMateriLock = (index, index2, quiz) => {
		const isFirstSubmaterial = index + index2 === 0;
		const isSubmaterialDone = materialItems[index]?.subitems[index2]?.is_done;
		const isPreviousSubmaterialDone =
			(index2 === 0 && materialItems[index]?.subitems[index2 - 1]?.is_done) ||
			materialItems[index]?.subitems[index2 - 1]?.is_done;

		const submateriProgress =
			JSON.parse(localStorage.getItem("SubmateriProgress")) || [];
		const userId = getUser().id;
		const currentSubmateriProgress = submateriProgress.find(
			(item) =>
				item.submateriId == materialItems[index]?.subitems[index2]?.id &&
				item.userId == userId
		);

		if (isSubmaterialDone) {
			// first submaterial in the first material OR submaterial done
			return false;
		}

		if (isPreviousSubmaterialDone || isFirstSubmaterial) {
			if (quiz === "pretest") {
				return false;
			}
			if (quiz === "posttest") {
				return currentSubmateriProgress?.is_learned !== true;
			}
			const requiredPretestLength = countTotalPretest(
				materialItems[index]?.subitems[index2]
			);
			return (
				requiredPretestLength >
				(currentSubmateriProgress?.PretestId?.length || 0)
			);
		} else {
			return true;
		}
	};

	const isContainPretest = (tryouts) => {
		return tryouts.some((item) => item.type === "pretest");
	};

	const isContainPosttest = (tryouts) => {
		return tryouts.some((item) => item.type === "posttest");
	};



	if (detailTryout && id) {
		return (
			<>
				<MenuWrapper>
					{!quizParam && (
						<Button
							component={RouterLink}
							to={tryoutParam?.membershipId ? `/classes/tryout/membership/${tryoutParam.membershipId}` : "/classes/tryout"}
							startIcon={<KeyboardBackspaceIcon />}
							sx={{ ml: 2, mb: 2 }}>
							Try Out
						</Button>
					)}
					<List
						component="div"
						subheader={
							<ListSubheader component="div" disableSticky>
								{!quizParam ? `Try Out` : `Quiz`}
							</ListSubheader>
						}>
						<SubMenuWrapper>
							<List component="div">
								{!quizParam && (
									<ListItem component="div">
										<Button
											disableRipple
											component={RouterLink}
											onClick={closeSidebar}
											to={`/classes/tryout/${id}/start`}
											startIcon={<PlayCircleFilledWhiteOutlinedIcon />}>
											Mulai Try Out
										</Button>
									</ListItem>
								)}
								<ListItem component="div">
									<Button
										disableRipple
										component={RouterLink}
										onClick={closeSidebar}
										to={`/classes/tryout/${id}/analytic`}
										startIcon={<DescriptionOutlinedIcon />}>
										Pembahasan
									</Button>
								</ListItem>
								<ListItem component="div">
									<Button
										disableRipple
										component={RouterLink}
										onClick={closeSidebar}
										to={`/classes/tryout/${id}/ranking`}
										startIcon={<EmojiEventsOutlinedIcon />}>
										Ranking
									</Button>
								</ListItem>
							</List>
						</SubMenuWrapper>
					</List>
					{quizParam && (
						<Button
							component={RouterLink}
							variant="contained"
							to={getLinkBackToCourse(quizParam)}
							startIcon={<KeyboardBackspaceIcon />}
							sx={{ ml: 2, mb: 2 }}>
							Kembali ke Materi
						</Button>
					)}
				</MenuWrapper>
			</>
		);
	}
	if ((detailCourse || detailClassRoom) && courseId) {
		return (
			<>
				<MenuWrapper>
					<Button
						component={RouterLink}
						to={detailClassRoom ? classId ? `/classes/classroom/${classId}/schedule` : "/classes/classroom" : membershipId ? `/classes/e-course/membership/${membershipId}` : '/classes/e-course'}
						startIcon={<KeyboardBackspaceIcon />}
						sx={{ ml: 2, mb: 2 }}>
						{detailClassRoom ? "Kembali" : "E-Course"}
					</Button>
					{!detailClassRoom && (
						<List
							component="div"
							subheader={
								<ListSubheader component="div" disableSticky>
									Pendahuluan
								</ListSubheader>
							}>
							<SubMenuWrapper>
								<List component="div">
									<ListItem component="div">
										<Button
											disableRipple
											component={RouterLink}
											onClick={closeSidebar}
											to={`/classes/e-course/${membershipId}/${courseId}`}
											style={{
												backgroundColor: "transparent",
											}}
											startIcon={<DescriptionOutlinedIcon />}>
											Deskripsi
										</Button>
									</ListItem>
								</List>
							</SubMenuWrapper>
						</List>
					)}
					<List
						component="div"
						subheader={
							<ListSubheader component="div" disableSticky>
								Materi
							</ListSubheader>
						}>
						{materialItems.map((item, index) => (
							<SubMenuWrapper key={"item" + index}>
								<List component="div">
									{item.isCollapse && (
										<>
											<ListItem component="div">
												<Button
													disableRipple
													onClick={item.onclick}
													startIcon={item.icon}
													fullWidth
													style={{
														textAlign: "left",
														backgroundColor: "#eaeaea",
														color: "black",
													}}
													endIcon={
														item.open ? <ExpandLessIcon /> : <ExpandMoreIcon />
													}>
													{item.title}
												</Button>
											</ListItem>
											<Collapse in={item.open} timeout="auto" unmountOnExit>
												<List
													component="div"
													disablePadding
													style={{ backgroundColor: "white" }}>
													{item.subitems.map((item, index2) => (
														<Box
															key={item.title}
														>
															{item.tryout.length > 0 &&
																isContainPretest(item.tryout) && (
																	<ListItem
																		style={{
																			cursor: getIsSubMateriLock(
																				index,
																				index2,
																				"pretest"
																			)
																				? "not-allowed"
																				: "pointer",
																		}}>
																		<Button
																			disableRipple
																			onClick={closeSidebar}
																			component={RouterLink}
																			to={`${item.to}?quiz=pretest`}
																			fullWidth
																			disabled={getIsSubMateriLock(
																				index,
																				index2,
																				"pretest"
																			)}
																			style={{
																				justifyContent: "flex-start",
																				color: getIsSubMateriLock(
																					index,
																					index2,
																					"pretest"
																				)
																					? "gray"
																					: "",
																			}}
																			startIcon={<QuizOutlinedIcon />}>
																			Pre Test
																		</Button>
																	</ListItem>
																)}
															<ListItem
																style={{
																	cursor: getIsSubMateriLock(
																		index,
																		index2,
																		null
																	)
																		? "not-allowed"
																		: "pointer",
																}}>
																<Button
																	disableRipple
																	onClick={closeSidebar}
																	component={RouterLink}
																	to={item.to}
																	fullWidth
																	disabled={getIsSubMateriLock(
																		index,
																		index2,
																		null
																	)}
																	style={{
																		justifyContent: "flex-start",
																		color: getIsSubMateriLock(
																			index,
																			index2,
																			null
																		)
																			? "gray"
																			: "black",
																	}}
																	startIcon={item.icon}>
																	{item.title}
																</Button>
															</ListItem>
															{item.tryout.length > 0 &&
																isContainPosttest(item.tryout) && (
																	<ListItem
																		style={{
																			cursor: getIsSubMateriLock(
																				index,
																				index2,
																				"posttest"
																			)
																				? "not-allowed"
																				: "pointer",
																		}}>
																		<Button
																			disableRipple
																			onClick={closeSidebar}
																			component={RouterLink}
																			to={`${item.to}?quiz=posttest`}
																			fullWidth
																			disabled={getIsSubMateriLock(
																				index,
																				index2,
																				"posttest"
																			)}
																			style={{
																				justifyContent: "flex-start",
																				color: getIsSubMateriLock(
																					index,
																					index2,
																					"posttest"
																				)
																					? "gray"
																					: "",
																			}}
																			startIcon={<QuizOutlinedIcon />}>
																			Quiz
																		</Button>
																	</ListItem>
																)}
														</Box>
													))}
												</List>
											</Collapse>
										</>
									)}
								</List>
							</SubMenuWrapper>
						))}
					</List>
				</MenuWrapper>
			</>
		);
	} else {
		return (
			<>
				<MenuWrapper>
					<List
						component="div"
						subheader={
							<ListSubheader component="div" disableSticky>
								Dashboards
							</ListSubheader>
						}>
						<SubMenuWrapper>
							<List component="div">
								<ListItem component="div">
									<Button
										disableRipple
										component={RouterLink}
										onClick={closeSidebar}
										to="/dashboards"
										startIcon={<DesignServicesTwoToneIcon />}>
										Overview
									</Button>
								</ListItem>
							</List>
						</SubMenuWrapper>
					</List>
					<List
						component="div"
						subheader={
							<ListSubheader component="div" disableSticky>
								Class
							</ListSubheader>
						}>
						<SubMenuWrapper>
							<List component="div" id="sidebar-classes">
								<ListItem component="div">
									<Button
										id="classes-e-course"
										className="classes-e-course"
										disableRipple
										component={RouterLink}
										onClick={closeSidebar}
										to="/classes/e-course"
										startIcon={<BallotTwoToneIcon />}>
										E-Courses
									</Button>
								</ListItem>
								<ListItem component="div">
									<Button
										id="classes-tryout"
										disableRipple
										component={RouterLink}
										onClick={closeSidebar}
										to="/classes/tryout"
										startIcon={<CalendarMonthSharpIcon />}>
										Tryouts
									</Button>
								</ListItem>
								<ListItem component="div">
									<Button
										id="classes-classroom"
										disableRipple
										component={RouterLink}
										onClick={closeSidebar}
										to="/classes/classroom"
										startIcon={<SchoolTwoToneIcon />}>
										Classes
									</Button>
								</ListItem>
							</List>
						</SubMenuWrapper>
					</List>
					<List
						component="div"
						subheader={
							<ListSubheader component="div" disableSticky>
								Others
							</ListSubheader>
						}>
						<SubMenuWrapper>
							<List component="div">
								<ListItem component="div">
									<Button
										disableRipple
										component={RouterLink}
										onClick={closeSidebar}
										to="/others/reports"
										startIcon={<ConfirmationNumberTwoToneIcon />}>
										Tickets
									</Button>
								</ListItem>
								<ListItem component="div">
									<Button
										disableRipple
										component={RouterLink}
										onClick={closeSidebar}
										to="/others/transactions"
										startIcon={<ShoppingCartSharpIcon />}>
										Transactions
									</Button>
								</ListItem>
								<ListItem component="div">
									<Button
										disableRipple
										component={RouterLink}
										onClick={closeSidebar}
										to="/others/account"
										startIcon={<AccountCircleTwoToneIcon />}>
										Account
									</Button>
								</ListItem>
								<ListItem component="div">
									<Button
										onClick={() => {
											closeSidebar();
											logout();
										}}
										startIcon={<LockOpenTwoToneIcon />}>
										Sign Out
									</Button>
								</ListItem>
							</List>
						</SubMenuWrapper>
					</List>
				</MenuWrapper>
			</>
		);
	}
}

export default SidebarMenu;
