import { Box, Container, Link, Typography, Grid, styled } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import StarsIcon from "@mui/icons-material/Stars";

const FooterLower = styled(Box)(
	({ theme }) => `
        background: ${theme.colors.alpha.black[100]};
`
);

const FooterUpper = styled(Box)(
	({ theme }) => `
		margin-top: ${theme.spacing(3)};
		padding: ${theme.spacing(4, 0)};
`
);

function FooterHome() {
	const baseUrl = window.location.origin;
	const contactInfo = [
		{
			label: "Admisi 1: +62 878-3929-7479",
			href: "https://wa.me/6287839297479",
			icon: <WhatsAppIcon style={{ color: "white", marginRight: "8px" }} />,
		},
		{
			label: "Admisi 2: +62 877-7615-3794",
			href: "https://wa.me/6287776153794",
			icon: <WhatsAppIcon style={{ color: "white", marginRight: "8px" }} />,
		},
		{
			label: "Telepon: +62 274-4281720",
			href: "tel:+622744281720",
			icon: <PhoneIcon style={{ color: "white", marginRight: "8px" }} />,
		},
		{
			label: "E-mail: bimbelyec@gmail.com",
			href: "mailto:bimbelyec@gmail.com",
			icon: (
				<EmailOutlinedIcon style={{ color: "white", marginRight: "8px" }} />
			),
		},
	];

	const socialMedia = [
		{
			label: "@bimbelYEC",
			href: "https://www.facebook.com/bimbelYEC",
			icon: <FacebookIcon style={{ color: "white", marginRight: "8px" }} />,
		},
		{
			label: "@bimbel_yec",
			href: "https://www.instagram.com/bimbel_yec/",
			icon: <InstagramIcon style={{ color: "white", marginRight: "8px" }} />,
		},
		{
			label: "@brofesional_id",
			href: "https://www.instagram.com/brofesional_id/",
			icon: <InstagramIcon style={{ color: "white", marginRight: "8px" }} />,
		},
		{
			label: "@brofesional_id",
			href: "https://x.com/brofesional_id",
			icon: <img src={`${process.env.REACT_APP_IMAGE_URL}static/images/icons/x_outlined.svg`} alt="x" style={{ color: "white", marginRight: "8px" }} />,
		},
		{
			label: "@BimbelYEC",
			href: "https://www.youtube.com/@BimbelYEC",
			icon: <YouTubeIcon style={{ color: "white", marginRight: "8px" }} />,
		},
		{
			label: "brofesionalid",
			href: "https://www.linkedin.com/company/brofesionalid/",
			icon: <LinkedInIcon style={{ color: "white", marginRight: "8px" }} />,
		},
	];

	const address = [
		{
			label: "Jl. Poncowolo 15, Ketanggungan, Wirobrajan, Yogyakarta 55252.",
			href: "https://maps.app.goo.gl/22ztEofUKpvZ3EuS7",
			icon: <MapsHomeWorkIcon style={{ color: "white", marginRight: "8px" }} />,
		},
		{
			label: "Berikan Ulasan",
			href: "https://g.page/r/CbQWYXslJEADEBM/review",
			icon: <StarsIcon style={{ color: "orange", marginRight: "8px" }} />,
		},
	];

	const FooterUpperComponent = () => {
		return (
			<FooterUpper style={{ background: "#1D2939" }}>
				<Container>
					<Grid container spacing={4}>
						<Grid item xs={12} md={3}>
							<Box mb={2}>
								<Typography variant="h5" color="white">
									Kontak
								</Typography>
							</Box>
							{contactInfo.map((contact, index) => (
								<Box
									key={`footer-contact-${index}`}
									sx={{ display: "flex", alignItems: "center", mb: 1 }}>
									{contact.icon}
									<Link href={contact.href}>
										<Typography variant="body1" color="white">
											{contact.label}
										</Typography>
									</Link>
								</Box>
							))}
						</Grid>
						<Grid item xs={12} md={3}>
							<Box mb={2}>
								<Typography variant="h5" color="white">
									Media Sosial
								</Typography>
							</Box>
							{socialMedia.map((contact, index) => (
								<Box
									key={`footer-social-${index}`}
									sx={{ display: "flex", alignItems: "center", mb: 1 }}>
									{contact.icon}
									<Link href={contact.href}>
										<Typography variant="body1" color="white">
											{contact.label}
										</Typography>
									</Link>
								</Box>
							))}
						</Grid>
						<Grid item xs={12} md={3}>
							<Box mb={2}>
								<Typography variant="h5" color="white">
									Jam Operasional
								</Typography>
							</Box>
							<Box>
								<Typography variant="body2" color="white" sx={{ mb: 1.2 }}>
									Senin - Jumat: 14.00 – 21.00 WIB
								</Typography>
								<Typography variant="body2" color="white" sx={{ mb: 1.2 }}>
									Sabtu: 14.00 – 18.00 WIB
								</Typography>
								<Typography variant="body2" color="white" sx={{ mb: 1.2 }}>
									Minggu & Hari Libur: Tutup
								</Typography>
								<Typography variant="body2" color="white" sx={{ mb: 1.2 }}>
									Ramadhan:
								</Typography>
								<Typography variant="body2" color="white" sx={{ mb: 1.2 }}>
									Senin - Jumat: 11.00 – 18.00 WIB
								</Typography>
								<Typography variant="body2" color="white" sx={{ mb: 1.2 }}>
									Sabtu: 11.00 - 15.00 WIB
								</Typography>
							</Box>
						</Grid>
						<Grid item xs={12} md={3}>
							<Box mb={2}>
								<Typography variant="h5" color="white">
									Kantor Brofesional
								</Typography>
							</Box>
							<Box>
								{address.map((contact, index) => (
									<Box
										key={`footer-address-${index}`}
										sx={{ display: "flex", alignItems: "center", mb: 1 }}>
										{contact.icon}
										<Link href={contact.href}>
											<Typography variant="body1" color="white">
												{contact.label}
											</Typography>
										</Link>
									</Box>
								))}
							</Box>
						</Grid>
					</Grid>
				</Container>
			</FooterUpper>
		);
	};

	const FooterLowerComponent = () => {
		const year = new Date().getFullYear();
		return (
			<FooterLower
				className="footer-wrapper"
				sx={{ position: "relative" }}
				id="footer">
				<Container>
					<Grid container spacing={4} pb={4}>
						<Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
							<Box
								display="flex"
								alignItems="center"
								justifyContent="flex-start"
								height="100%">
								<Typography variant="subtitle1" color="white">
									&copy; {year} Brofesional. All rights reserved.
								</Typography>
							</Box>
						</Grid>
						<Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
							<Box display="flex" alignItems="center" justifyContent={{ xs: 'flex-start', md: 'flex-end' }}>
								<Box>
									<Typography variant="subtitle1" color="white">
										<Link
											href={`${baseUrl}/privacy-policy`}
											target="_blank"
											rel="noopener noreferrer"
											color="white">
											Privacy Policy
										</Link>
									</Typography>
									<Typography variant="subtitle1" color="white">
										<Link
											href={`${baseUrl}/terms-of-services`}
											target="_blank"
											rel="noopener noreferrer"
											color="white">
											Term of Services
										</Link>
									</Typography>
									<Typography variant="subtitle1" color="white">
										<Link
											href={`${baseUrl}/about`}
											target="_blank"
											rel="noopener noreferrer"
											color="white">
											About Brofesional
										</Link>
									</Typography>
								</Box>
							</Box>
						</Grid>
					</Grid>
				</Container>
			</FooterLower>
		);
	};

	return (
		<>
			<FooterUpperComponent />
			<FooterLowerComponent />
		</>
	);
}

export default FooterHome;
