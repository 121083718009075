import { useRef, useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import {
	Avatar,
	Box,
	Button,
	Divider,
	Hidden,
	lighten,
	List,
	ListItem,
	ListItemText,
	Popover,
	Typography,
	styled,
} from "@mui/material";
import ExpandMoreTwoToneIcon from "@mui/icons-material/ExpandMoreTwoTone";
import AccountBoxTwoToneIcon from "@mui/icons-material/AccountBoxTwoTone";
import LockOpenTwoToneIcon from "@mui/icons-material/LockOpenTwoTone";
import AccountTreeTwoToneIcon from "@mui/icons-material/AccountTreeTwoTone";
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';
import { SidebarContext } from 'src/contexts/SidebarContext';
import getUser from "src/models/User";
import { logout } from "src/services/auth.service";

const BoxWrapper = styled(Box)(
	({ theme }) => `
            padding-left: ${theme.spacing(2)};
            padding-right: ${theme.spacing(2)};
            padding-top: ${theme.spacing(0)};
    `
);

const MenuUserBox = styled(Box)(
	({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxButton = styled(Button)(
	({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(0)};
`
);

const UserBoxText = styled(Box)(
	({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
	({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
	({ theme }) => `
        color: ${lighten(theme.palette.secondary.main, 0.5)}
`
);

const UserBox = ({nameUser}) => {
	const user = getUser();
	const ref = useRef<any>(null);
	const [isOpen, setOpen] = useState<boolean>(false);
	const { closeSidebar } = useContext(SidebarContext);

	const handleOpen = (): void => {
		setOpen(true);
	};

	const handleClose = (): void => {
		setOpen(false);
	};

	const getFirstSecondName = (name: string) => {
		const nameArray = name.split(" ");
		const firstName = nameArray[0];
		const secondName = nameArray[1];
		return `${firstName} ${secondName ?? ""}`;
	};

	const handleLogout = () => {
		console.log("logout");
		setOpen(false);
		closeSidebar();
		logout();
	};

	const truncateText = (text: string, maxLength: number) => {
		return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
	};

	return (
		<>
			<BoxWrapper ref={ref} onClick={handleOpen}>
				<UserBoxButton id="user-box-button" color="secondary">
					<Avatar variant="rounded" alt={user.name} src={user.photo} />
					{
						nameUser &&
						<Hidden>
							<UserBoxText>
								<UserBoxLabel variant="body1">
									{truncateText(user.name, 13)}
								</UserBoxLabel>
								<UserBoxDescription variant="body2">
									{truncateText(user.email, 15)}
								</UserBoxDescription>
							</UserBoxText>
						</Hidden>
					}
					<Hidden>
						<ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
					</Hidden>
				</UserBoxButton>
			</BoxWrapper>
			<Popover
				anchorEl={ref.current}
				onClose={handleClose}
				open={isOpen}
				anchorOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}>
				<MenuUserBox sx={{ minWidth: 210 }} display="flex">
					<Avatar variant="rounded" alt={user.name} src={user.photo} />
					<UserBoxText>
						<UserBoxLabel variant="body1">{user.name}</UserBoxLabel>
						<UserBoxDescription variant="body2">
							{user.email}
						</UserBoxDescription>
					</UserBoxText>
				</MenuUserBox>
				<Divider sx={{ mb: 0 }} />
				<List sx={{ p: 1 }} component="nav">
					<ListItem
						button
						onClick={closeSidebar}
						to="/"
						component={NavLink}>
						<WidgetsOutlinedIcon fontSize="small" />
						<ListItemText primary="Home Page" />
					</ListItem>
					<ListItem button onClick={closeSidebar} to="/dashboards" component={NavLink}>
						<AccountTreeTwoToneIcon fontSize="small" />
						<ListItemText primary="E-Learning" />
					</ListItem>
					<ListItem button onClick={closeSidebar} to="/others/account" component={NavLink}>
						<AccountBoxTwoToneIcon fontSize="small" />
						<ListItemText primary="My Profile" />
					</ListItem>
				</List>
				<Divider />
				<Box sx={{ m: 1 }}>
					<Button color="primary" fullWidth onClick={handleLogout}>
						<LockOpenTwoToneIcon sx={{ mr: 1 }} />
						Sign out
					</Button>
				</Box>
			</Popover>
		</>
	);
};

export default UserBox;
